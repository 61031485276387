.landing-section{
    height: 80vh;
  }

  .bg1{
    background-image: url("../../../static/images/sideKaImage.webp");
    background-size: cover;
    height: 80vh;
  }
  .bg2{
    background-image: url("../../../static/images/sideKaLanding.jpeg");
    background-size: cover;
    height: 80vh;
  }
  .bg3{
    background-image: url("../../../static/images/bichKaLanding.avif");

    background-size: cover;
    height: 80vh;
  }

  /* .container-custome {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
  }
   */

  
  .container-custom .content {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 31%;
    padding: 20px;
  }

  @media (min-width: 576px){
  .jumbotron {
      padding: 4rem 2rem;
  }
}

.jumbotron {
    padding: 2rem 1rem;
    /* margin-bottom: 2rem; */
    /* background-color: #e9ecef; */
    /* background-color: #594f4a; */
    background-color: #5e534d;
    border-radius: 0.3rem;
    height: inherit;
}


.jumbotron-original{
   background-color: #e9ecef;
   padding: 2rem 1rem;
   /* margin-bottom: 2rem; */
   border-radius: 0.3rem;
   height: inherit;
}

.card{
  background-color: transparent !important;
  --bs-card-border-color: transparent !important;
}

.img-wrap img{
  width:inherit;
}

.img-wrap{
  width:inherit;
}

@media screen and (max-width:768px){
    .low-screen-visibility{
      display:block;
    }
    .landing-section{
      display:none;
    }
    .container-custom .content {
      position: absolute;
      bottom: 0;
      background: rgb(0, 0, 0); /* Fallback color */
      background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
      color: #f1f1f1;
      width: 100%;
      padding: 20px;
    }
  
  }
  @media screen and (min-width:768px){
    .low-screen-visibility{
      display:none;
    }
    .landing-section{
      display:block;
    }
    .container-custom .content {
      width: 31%;
  
    }
  
  }
  
  @media screen and (max-width:1022px) and (min-width:769px){
    .container-custom .content {
      position: absolute;
      bottom: 0;
      background: rgb(0, 0, 0); /* Fallback color */
      background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
      color: #f1f1f1;
      width: 31%;
      padding: 20px;
    }
    .container-custom .content h1{
      font-size:24px !important;
    }
    .container-custom .content p{
      font-size:14px;
    }
  }