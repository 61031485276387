.creative-slider {
    width: 100%;
    /* height:80vh; */
    margin: 0 auto;
  }
  
  .slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; 
    width:100vw;
  }
  
  .slider-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .hero-image img{
    /* filter:blur(2px); */
  }

.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
  }
  
  .hero-text button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 10px 25px;
    color: black;
    background-color: #ddd;
    text-align: center;
    cursor: pointer;
  }
  
  .hero-text button:hover {
    background-color: #555;
    color: white;
  }

  .container-fluid{
    padding-right:0px !important;
    padding-left : 0px !important;
  }